import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';

// Utils
import { cn } from 'utils';

// Styles
import styles from './ResidenceCard.module.css';

function ResidenceCard({
  address, price, media, slug, theme, name, id, tag, template, TitleTag, orientation,
  className, classNameLink,
}) {
  return (
    <Link
      href={slug}
      id={id}
      className={cn([styles.container,
        styles[theme],
        styles[template],
        styles[orientation],
        className,
        classNameLink,
      ])}
    >
      <div className={styles.image}>
        {media?.url ? (
          <Image
            src={media.url}
            alt={media?.description}
            fill
            sizes="33vw"
          />
        ) : null}
      </div>
      <div className={cn([styles.info, 'card-residence_info'])}>
        <div className={styles.content}>
          <TitleTag className={styles.city}>
            {name}
          </TitleTag>
          <span className={styles.address}>
            {address}
          </span>
        </div>
        <span className={styles.price}>
          {price}
        </span>
      </div>

      {tag ? <div className={styles.tag}>{tag}</div> : null}
    </Link>
  );
}

ResidenceCard.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  price: PropTypes.string,
  media: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
  }),
  slug: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  id: PropTypes.string,
  tag: PropTypes.node,
  template: PropTypes.string,
  TitleTag: PropTypes.oneOf(['h2', 'h3']),
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  className: PropTypes.string,
  classNameLink: PropTypes.string,
};

ResidenceCard.defaultProps = {
  name: '',
  address: '',
  price: '',
  media: {
    url: '',
    description: '',
  },
  slug: '',
  theme: 'light',
  id: null,
  tag: null,
  template: '',
  TitleTag: 'h3',
  orientation: 'vertical',
  className: '',
  classNameLink: '',
};

export default ResidenceCard;
